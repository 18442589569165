import * as React from "react";
import { withLayout, LayoutProps } from "../components/Layout";
import { Segment, Container, Grid, Header, Icon } from "semantic-ui-react";

const IndexPage = (props: LayoutProps) => {
  return (
    <div>
      <Segment vertical textAlign="center" className="masthead">
        <Container>
          <Header as="h1" inverted>
            Изграждане и поддръжка на електроинсталации
          </Header>
          <br />
        </Container>
      </Segment>
      <Grid
        textAlign="center"
        stackable
        className="container"
        style={{ marginTop: "-155px" }}
      >
        <Grid.Row>
          <Grid.Column stretched width="7">
            <Segment
              placeholder
              color="yellow"
              className="working-hours"
              padded
            >
              <Header icon>
                <Icon name="clock" />
                <br />
                Работно време
              </Header>
              <p>
                Понеделник - Петък:
                <span>08:00 - 17:00</span>
              </p>
              <p>
                Събота:
                <span>Почивен ден</span>
              </p>
              <p>
                Неделя:
                <span>Почивен ден</span>
              </p>
            </Segment>
          </Grid.Column>
          <Grid.Column stretched width="3"></Grid.Column>
          <Grid.Column stretched width="6">
            <Segment placeholder size="huge" raised color="yellow">
              <Header icon>
                <Icon name="phone" color="blue" />
                <br />
                <Header.Subheader>Потърсете ни на</Header.Subheader>
                +359 88 490 7045
              </Header>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* About this starter */}
      <Segment vertical className="stripe">
        <Grid stackable verticalAlign="middle" className="container">
          <Grid.Row>
            <Grid.Column width="12">
              <Header size="huge">За нас</Header>
              <p>
                Ние извършваме електро услуги на територията на градовете
                Банско, Разлог и околните градове и села. Нашият екип е изграден от доказани и
                квалифицирани електротехници с дългогодишен опит както на
                българския така и на европейския пазар. Реагираме бързо и
                подходждаме лично към всеки клиент.

                Не се колебайте да се свържете с нас!
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  );
};
export default withLayout(IndexPage);
